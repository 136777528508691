import React from "react";
import {VscDebugBreakpointLogUnverified} from "react-icons/vsc";
import Section from "../../Section";
import CorporateClashOptionsMenu from "../../../../Assets/Projects/CorporateClash/CorporateClashOptionsMenu.png";

const ExploitPreventionSection: React.FC = () => {
    return (
        <Section title="Exploit Prevention">
            <p style={{textAlign: "left"}}>
                Malicious players were exploiting tools like <b className="purple">Cheat Engine</b> to spam teleport
                requests and clutter livestreamers’ screens. To prevent this, I developed a multi‐layered solution:
            </p>

            <ul>
                <li className="about-activity">
                    <VscDebugBreakpointLogUnverified/> <b className="purple">Action Lock:</b> Users can no longer click
                    the teleport button once a teleport is already in progress.
                </li>
                <li className="about-activity">
                    <VscDebugBreakpointLogUnverified/> <b className="purple">Client‐Side Rate Limiting:</b> Only a set
                    number of requests can be sent per second, even if the UI is bypassed.
                </li>
                <li className="about-activity">
                    <VscDebugBreakpointLogUnverified/> <b className="purple">Server‐Side Redundancy:</b> A second rate
                    limiter on the server provides an extra safeguard against spam.
                </li>
                <li className="about-activity">
                    <VscDebugBreakpointLogUnverified/> <b className="purple">Suspicious Activity Alerts:</b> Users who
                    hit these limits trigger automatic flags and detailed logs for moderation.
                </li>
                <li className="about-activity">
                    <VscDebugBreakpointLogUnverified/> <b className="purple">Privacy Settings:</b> A new option lets
                    players opt out of teleport requests from anyone who isn’t on their friends list.
                </li>
            </ul>

            <p style={{textAlign: "left"}}>
                This comprehensive approach <b className="purple">eliminated the exploit</b> and safeguarded the game’s
                community from harassment.
            </p>

            <img
                src={CorporateClashOptionsMenu}
                alt="Corporate Clash Options Menu"
                className="display-image-container rounded"
            />
        </Section>
    );
};

export default ExploitPreventionSection;
